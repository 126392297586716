import React from 'react'
import s from './policy.module.scss'

export const Policy = () => {
	return (
		<div className='container'>
			<div className={s.wrapper}>
				<h2>ПОЛИТИКА SOCIALPOST В ОБЛАСТИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ </h2>
				<p>1. Общие положения <br /><br />

					1.1. Настоящая Политика обработки персональных данных (далее - Политика) разработана на основании Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее – Закон) и определяет политику SocialPost (далее – Компания) в отношении обработки персональных данных, сбор которых осуществляется с использованием сайта Компании: www.socialpost.ru и его поддоменов (далее – Сервис). <br />
					1.2. Политика действует в отношении персональных данных, которые относятся к физическому лицу, использующему Сервис, в том числе вступающему в договорные отношения с Компанией (далее - Пользователь). <br />
					1.3. Целью Политики является обеспечение защиты прав и свобод Пользователя в процессе сбора и обработки его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну, четкое и неукоснительное соблюдение требований российского законодательства в области персональных данных.<br />
					1.4. Действие Политики распространяется на персональные данные Пользователей, обрабатываемых Компанией с применением средств автоматизации и без применения таковых. <br />
					1.5. Политика опубликована в свободном доступе сети Интернет в Сервисе по ссылке www.socialpost.ru.<br />
					1.6. Компания вправе вносить изменения в Политику по мере необходимости. <br />
					1.7. Политика регулируется и подлежит толкованию в соответствии российским правом.<br />
				</p>
				<p>2. Термины, используемые в Политике <br /><br />

				Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу - субъекту персональных данных. Физическое лицо, являющееся Пользователем Сервиса, является субъектом персональных данных. <br />
				Специальные категории персональных данных – данные, касающиеся расовой, национальной принадлежности, политических взглядов, религиозных или философских убеждений, состояния здоровья и интимной жизни. <br />
				Биометрические персональные данные - сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность и которые используются Оператором для установления личности субъекта персональных данных. <br />
				Оператор персональных данных - юридическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия, совершаемые с персональными данными. Обрабатывая персональные данные Пользователей в связи с использованием Сервиса, Компания выступает оператором персональных данных. <br />
				Конфиденциальность персональных данных – обязательное для соблюдения Оператором или лицами, получившими доступ к персональным данным, требование не допускать их распространение без согласия субъекта персональных данных или наличия иного законного основания. <br />
				Обработка персональных данных - любое действие или их совокупность, совершаемых с использованием средств автоматизации или без использования таковых с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. <br />
				Автоматизированная обработка персональных данных - обработка персональных данных с помощью средств вычислительной техники. <br />
				Неавтоматизированная обработка персональных данных - обработка персональных данных при непосредственном участии человека без помощи средств вычислительной техники. <br />
				Блокирование персональных данных - временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных). <br />
				Распространение персональных данных - действия, направленные на раскрытие персональных данных неопределенному кругу лиц. <br />
				Предоставление персональных данных - действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц. <br />
				Уничтожение персональных данных - действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных. <br />
				Обезличивание персональных данных - действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных. <br />
				Трансграничная передача персональных данных - передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу. <br />
				Информационная система персональных данных - совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств. <br />
				</p>
				<p>3. Цели сбора и обработки персональных данных <br /><br />

				Целями сбора и обработки персональных данных Компанией являются: <br/>
				• идентификация Пользователя;<br/>
				• взаимодействие Компании с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся действий Компании, а также обработка запросов и заявок от Пользователя (взаимодействие с Пользователем может осуществляться через службу поддержки Сервиса, по номеру телефона Пользователя и/или по указанному Пользователем адресу электронной почты);<br/>
				• предоставление Пользователю справочной информации и/или технической помощи при работе с Сервисом;<br/>
				• продвижение услуг Компании;<br/>
				• участие Пользователя в мероприятиях, организуемых Компанией;<br/>
				• обеспечение работоспособности и безопасности Сервиса;<br/>
				• информирование Пользователя;<br/>
				• информирование Пользователя об изменении функционала Сервиса, включая любые изменения, связанные использованием личного кабинета в Сервисе;<br/>
				• обеспечение качественной работы Сервиса, а также личного кабинета Пользователя в Сервисе;<br/>
				• обеспечение исполнения законодательства, а также законных требований, решений, поручений и запросов органов государственной власти и лиц, действующих по поручению или от имени таких органов.<br/>
				</p>
				<p>4. Категории Пользователей и перечень обрабатываемых персональных данных<br /><br />

				4.1. Компания может осуществлять сбор и обработку персональных данных следующих субъектов персональных данных:<br/>
				• Пользователи, которые получают доступ к Сервису; <br/>
				• Пользователи, которые акцептовали договор-оферту, размещенную в Сервисе по ссылке www.socialpost.ru. <br/>
				4.2.  Компания обрабатывает следующие персональные данные Пользователей при пользовании Сервисом:<br/>
				• данные о технических устройствах: IP-адрес, вид операционной системы, тип браузера, географическое положение, дата и время пользования Сервисом; <br/>
				• сведения о посещаемых страницах Сервиса;<br/>
				• информация, полученная в результате действий Пользователя в Сервисе;<br/>
				• информация, сбор которой осуществляется автоматически с помощью файлов cookies, то есть небольших фрагментов данных, отправленных Сервисом и хранимых на устройстве Пользователя. Эти файлы содержат данные, которые не наносят ущерба оборудованию Пользователя. Они могут использоваться для записи предпочтений Пользователя (таких как выбранный язык и о том, как Пользователь использует Сервис). Эти файлы помогают Компании улучшить содержание Сервиса и упростить его использование.<br/>
				4.3.  Компания обрабатывает следующие персональные данные Пользователей при отправке заявки на размещения: <br/>
				• фамилия, имя, отчество;<br/>
				• адрес электронной почты;<br/>
				• номер мобильного телефона; <br/>
				• ссылка на аккаунт в мессенджере Telegram.<br/>
				4.5. Компания как правообладатель Сервиса при обработке персональных данных вправе контролировать использование Сервиса, в том числе в целях соблюдения прав Пользователей при такой обработке персональных данных.<br/>
				</p>
				<p>5. Основания обработки персональных данных<br /><br />

				Компания обрабатывает персональные данные только при наличии законных оснований для их обработки, к которым относятся: <br/>
				• согласие Пользователя на обработку персональных данных;<br/>
				• исполнение договоров, заключаемые между Компанией и Пользователем;<br/>
				• выполнение функций, полномочий и обязанностей, возложенных на Компанию российским законодательством и Уставом Компании.<br/>
				</p>
				<p>6. Принципы обработки персональных данных посредством Сервиса<br /><br />

				6.1. Персональные данные обрабатываются на основе принципов законности, справедливости и прозрачности. <br/>
				6.2. Компания соблюдает конфиденциальность персональных данных Пользователей и не раскрывает их третьим лицам без соответствующего согласия или иного законного основания.<br/>
				6.3. Конфиденциальность персональных данных соблюдается, в том числе путем принятия правовых, организационных и технических мер защиты персональных данных от несанкционированных или неправомерных доступа, изменения, ограничения, копирования, передачи, распространения, уничтожения и иных неправомерных действий в отношении персональных данных.<br/>
				6.4. Обработка персональных данных осуществляется для конкретных и заранее определенных целей. Хранение персональных данных не осуществляется после достижения целей обработки, за исключением случаев, предусмотренных законодательством РФ.<br/>
				6.5. При обработке персональных данных обеспечивается точность, достаточность и актуальность персональных данных, а также их согласованность с целями обработки. <br/>
				6.6. При принятии каких-либо решений, затрагивающих интересы физических лиц, Компания не основывает такие решения на автоматизированной обработке персональных данных, кроме случаев, когда Пользователем предоставлено соответствующее согласие. <br/>
				6.7. Компания не собирает и не обрабатывает посредством Сервиса биометрические персональные данные и специальные категории персональных данных.<br/>

				</p>
				<p>7. Способы и сроки обработки персональных данных<br /><br />

				7.1. Компания может осуществлять обработку персональных данных с использованием средств автоматизации или без использования таких средств путем сбора, записи, систематизации, накопления, хранения, уточнения (обновления, изменения), извлечения, использования, передача (предоставление, доступ), за исключением распространения и трансграничной передачи, блокирования, удаления, уничтожения.<br/>
				7.2. По общему правилу Компания не передает персональные данные третьим лицам без согласия Пользователя. <br/>
				7.3. С согласия Пользователя Компания передает персональные данные Пользователя третьим лицам для целей приема и осуществления платежей: выплаты Пользователю вознаграждения, а также расчета и уплаты налогов (если применимо), пополнение баланса личного кабинета. <br/>
				7.4. Персональные данные могут быть переданы без согласия Пользователя в следующих случаях:<br/>
				• в целях соблюдения требований применимого законодательства, решений суда и запросов государственных и муниципальных органов;<br/>
				• в связи с расследованием, проводимым правоохранительными органами;<br/>
				• для целей расследования или содействия в предотвращении случаев нарушения применимого законодательства и настоящей Политики;<br/>
				• в целях защиты прав и имущества, а также обеспечения безопасности Компании и ее работников, Пользователей Сайта или иных лиц, а также в других случаях, предусмотренных применимым законодательством;<br/>
				7.5. Обработка персональных ограничивается достижением конкретных, заранее определенных и законных целей их обработки, указанных в Политике.<br/>
				7.6. Персональные данные Пользователя обрабатываются в течение всего срока использования Пользователем Сервиса. <br/>
				7.7. Персональные данные Пользователей обрабатываются в течение 5 лет (в целях исполнения Компанией обязанностей, установленных законодательством РФ в сфере налогов и бухгалтерского учета).<br/>
				7.8. По достижении целей обработки персональные данные подлежат уничтожению в течение 30 (тридцати) дней. Компания уничтожит персональные данные, а также обеспечит их уничтожение привлеченными к обработке третьими лицами. <br/>
				7.9. Обработка персональных данных прекращается в течение 30 календарных дней при отзыве Пользователем согласия на их обработку (в случаях, когда согласие является единственным законным основанием для обработки персональных данных Пользователя).<br/>
				7.10. Обработка персональных данных может быть продолжена после отзыва Пользователем согласия, если это требуется для выполнения обязанностей Компании, возникающих в силу применимого законодательства и/или, когда обработка необходима для осуществления прав и законных интересов Компании и/или третьих лиц.<br/>
				</p>
				<p>8. Согласие на обработку персональных данных<br /><br />

				8.1. Пользователь принимает решение о предоставлении Компании своих персональных данных свободно, своей волей и в своем интересе.<br/>
				8.2. Используя Сервис, предоставляя свои персональные данные при отправке заявки на размещение, проставляя галочку и нажимая кнопку «Принимаю политику конфидециальности», Пользователь выражает согласие и дает согласие на обработку своих персональных данных в порядке, предусмотренном Политикой.<br/>
				8.3. Согласие на обработку персональных данных может быть отозвано Пользователем в порядке, предусмотренном разделом 10 Политики.<br/>
				</p>
				<p>9. Основные права и обязанности Пользователя и Компании<br /><br />

				9.1. Пользователь как субъект персональных данных вправе: <br/>
				• получать информацию об обработке Компанией его персональных данных путем направления запросов;<br/>
				• обжаловать действия или бездействие Компании как оператора персональных данных.<br/>
				9.2. Запрос должен содержать:<br/>
				• номер основного документа, удостоверяющего личность Пользователя или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе;<br/>
				• сведения, подтверждающие участие Пользователя в отношениях с Компанией либо сведения, подтверждающие факт обработки Компанией персональных Пользователя;<br/>
				• собственноручную или электронную подпись Пользователя или его представителя. При подписании запроса представителем к запросу должна прилагаться доверенность. <br/>
				9.3. Компания как оператор персональных данных обязана:<br/>
				• предоставлять по запросу Пользователя информацию об обработке его персональных данных или обоснованный отказ;<br/>
				• по требованию Пользователя уточнять обрабатываемые персональные данные, блокировать или удалять, если они являются неполными, устаревшими, неточными, незаконно полученными или ненужными для заявленной цели обработки;<br/>
				• обеспечить правомерность обработки персональных данных;<br/>
				• в случае отзыва Пользователем согласия на обработку персональных данных прекратить их обработку и уничтожить в срок, не превышающий 30 (тридцати) календарных дней с даты поступления указанного отзыва, за исключением случаев, когда обработка может быть продолжена в соответствии с законодательством.<br/>
				</p>
				<p>10.  Обращения Пользователей<br /><br />

				10.1. Для отзыва согласия на обработку персональных данных или осуществления иных прав, получения от Компании определенной информации или ответов на вопросы, связанные с настоящей Политикой или обработкой персональных данных Пользователь вправе направить запрос на электронную почту support@socialpost.ru.
				</p>
			</div>
		</div>
	)
}
