import React from 'react'

export const MotionStar = () => {
	return (
		<g>
				<path
						d="M333.292 900.262L352.316 879.762L349.149 877.115L349.034 877.403L328.551 859.529C328.301 859.299 328.512 858.896 328.839 858.954L342.104 861.715C342.104 861.715 342.257 861.62 342.334 861.581L365.908 850.324C367.117 849.749 368.307 848.617 369.056 847.332L372.224 841.886L371.993 842.193L366.253 836.133L377.925 809.821C378.021 809.61 378.29 809.552 378.463 809.706L389.558 819.39C389.769 819.582 389.942 819.832 390.038 820.158L394.953 834.867L410.636 825.489C410.848 825.355 411.116 825.393 411.289 825.546L421.579 834.522L422.423 835.27C423.479 836.209 423.191 838.53 421.329 840.62L401.46 863C400.443 864.151 399.79 865.532 399.752 866.682L399.003 888.967C398.907 891.863 395.337 895.276 392.956 894.739L374.681 890.578C373.74 890.367 372.473 890.789 371.302 891.728L348.631 909.87C346.999 911.174 345.502 911.328 344.638 910.695L333.35 900.876C333.158 900.723 333.158 900.435 333.311 900.262H333.292Z"
						fill="#7C99FF"
					/>
					<path
						d="M415.301 845.817L414.457 845.069L404.186 836.094C403.994 835.941 403.726 835.902 403.534 836.037L387.85 845.395L382.935 830.686C382.82 830.36 382.667 830.111 382.455 829.919L372.761 821.462L377.925 809.821C378.021 809.61 378.29 809.552 378.463 809.706L389.558 819.39C389.769 819.582 389.942 819.832 390.038 820.158L394.953 834.867L410.636 825.489C410.848 825.355 411.116 825.393 411.289 825.546L421.579 834.522L422.423 835.27C423.479 836.209 423.191 838.53 421.329 840.62L415.858 846.795C415.762 846.393 415.589 846.067 415.321 845.817H415.301Z"
						fill="white"
					/>
					<path
						d="M392.649 877.249C392.688 876.098 393.321 874.718 394.358 873.567L399.752 867.507L399.022 888.986C398.926 891.882 395.356 895.295 392.975 894.758L392.092 894.547L392.668 877.268L392.649 877.249Z"
						fill="#7C99FF"
					/>
					<path
						d="M398.082 893.224C399.214 891.94 399.905 890.405 399.963 889.005L400.711 866.721C400.75 865.8 401.307 864.669 402.17 863.633L422.039 841.272C424.208 838.818 424.612 835.941 423.057 834.56L411.922 824.837C411.423 824.396 410.713 824.339 410.137 824.684L395.471 833.429L390.94 819.851C390.787 819.391 390.537 818.988 390.172 818.681L379.077 808.977C378.769 808.709 378.347 808.594 377.944 808.69C377.541 808.786 377.195 809.054 377.042 809.438L365.101 836.325L370.822 842.365L368.211 846.853C367.558 847.985 366.541 848.963 365.485 849.461L341.931 860.699L329.03 858.014C328.454 857.899 327.879 858.168 327.61 858.705C327.341 859.242 327.475 859.855 327.917 860.239L349.379 878.975L349.513 878.669L350.915 879.838L332.582 899.591C332.332 899.841 332.198 900.243 332.217 900.608C332.236 900.972 332.409 901.337 332.697 901.586L343.985 911.405C345.367 912.421 347.344 912.095 349.225 910.599L371.897 892.457C372.818 891.729 373.817 891.345 374.469 891.498L392.745 895.66C393.973 895.948 395.413 895.507 396.795 894.433C397.275 894.068 397.698 893.665 398.082 893.224ZM400.692 862.425C399.56 863.729 398.849 865.302 398.792 866.663L398.043 888.948C398.005 890.233 397.006 891.863 395.624 892.937C394.722 893.627 393.801 893.953 393.167 893.819L374.892 889.657C373.644 889.37 372.127 889.868 370.707 891L348.035 909.142C346.749 910.177 345.655 910.292 345.194 909.947L334.367 900.55L353.698 879.723L348.784 875.619L348.669 875.869L330.988 860.431L342.238 862.77L342.737 862.502L366.311 851.245C367.693 850.573 369.037 849.327 369.862 847.869L373.03 842.423L372.3 841.944L372.665 841.598L367.367 835.999L378.462 810.972L388.925 820.1C388.925 820.1 389.078 820.292 389.136 820.446L394.415 836.286L410.867 826.467L421.79 835.999C422.346 836.478 422.135 838.338 420.599 839.987L400.731 862.368L400.673 862.425H400.692Z"
						fill="#141516"
					/>
					<path
						d="M372.07 812.372L357.595 837.341C356.847 838.626 355.676 839.757 354.447 840.332L330.873 851.59C327.802 853.047 325.863 857.247 327.591 858.685L340.856 869.77C341.547 870.345 341.681 871.458 341.24 872.743L332.678 897.693C331.564 900.934 334.214 902.219 337.17 899.86L359.841 881.718C361.012 880.778 362.279 880.356 363.22 880.567L381.495 884.729C383.876 885.266 387.446 881.871 387.542 878.956L388.291 856.672C388.329 855.521 388.963 854.14 390 852.99L409.888 830.629C412.479 827.714 412.038 824.338 409.139 824.895L386.928 829.248C385.776 829.478 384.912 829.056 384.605 828.116L378.597 810.147C377.81 807.807 373.97 809.111 372.089 812.372H372.07Z"
						fill="white"
					/>
					<path
						d="M407.814 832.527L389.789 852.817C388.771 853.968 388.118 855.349 388.08 856.499L387.331 878.784C387.235 881.679 383.665 885.093 381.284 884.556L363.009 880.395C362.068 880.184 360.801 880.606 359.63 881.545L342.468 895.257L348.131 878.764C348.573 877.48 348.439 876.367 347.747 875.792L341.086 870.23C340.971 869.981 340.837 869.751 340.645 869.578L334.003 864.036C333.273 862.253 335.097 858.877 337.765 857.612L361.339 846.354C362.548 845.779 363.738 844.648 364.487 843.363L378.962 818.393C379.403 817.646 379.941 816.994 380.536 816.457L384.375 827.963C384.682 828.903 385.565 829.306 386.698 829.095L389.597 828.519L391.478 834.157C391.785 835.078 392.668 835.5 393.801 835.289L407.814 832.547V832.527Z"
						fill="#F14C4C"
					/>
					<path
						d="M386.64 883.233C387.773 881.948 388.464 880.414 388.522 879.014L389.27 856.729C389.309 855.809 389.865 854.562 390.729 853.642L410.598 831.281C412.364 829.229 413.036 826.793 412.134 825.201C411.519 824.147 410.368 823.686 408.947 823.974L386.736 828.327C386.064 828.462 385.661 828.289 385.508 827.848L379.499 809.84C379.154 808.785 378.309 808.133 377.215 808.018C375.18 807.807 372.665 809.437 371.244 811.892L356.77 836.861C356.117 837.993 355.1 838.971 354.044 839.47L330.47 850.727C328.224 851.801 326.324 854.313 326.036 856.557C325.882 857.765 326.228 858.781 326.996 859.433L340.261 870.499C340.625 870.806 340.664 871.554 340.357 872.436L331.795 897.386C331.181 899.189 331.488 900.723 332.64 901.528C333.964 902.449 335.941 902.104 337.803 900.608L360.475 882.466C361.396 881.737 362.395 881.353 363.047 881.507L381.323 885.668C382.551 885.956 383.991 885.515 385.373 884.441C385.853 884.077 386.276 883.674 386.659 883.233H386.64ZM389.251 852.434C388.099 853.738 387.408 855.31 387.351 856.672L386.602 878.956C386.563 880.241 385.565 881.871 384.183 882.945C383.281 883.636 382.359 883.962 381.726 883.827L363.45 879.666C362.203 879.378 360.686 879.877 359.266 881.008L336.594 899.15C335.289 900.186 334.194 900.32 333.734 899.994C333.331 899.706 333.292 898.958 333.599 898.038L342.161 873.088C342.737 871.4 342.487 869.904 341.489 869.061L328.224 857.976C327.898 857.707 327.898 857.19 327.936 856.825C328.109 855.387 329.414 853.373 331.296 852.472L354.869 841.215C356.252 840.543 357.595 839.297 358.421 837.839L372.915 812.851C374.047 810.895 375.928 809.821 377.023 809.936C377.368 809.974 377.579 810.128 377.695 810.454L383.684 828.442C384.145 829.823 385.488 830.533 387.101 830.207L409.312 825.853C409.907 825.738 410.291 825.853 410.464 826.16C410.809 826.755 410.694 828.404 409.158 830.015L389.289 852.395L389.232 852.453L389.251 852.434Z"
						fill="#141516"
					/>
					<path
						d="M385.527 883.06L396.968 893.052L394.262 894.682L373.03 890.769L361.109 880.356L364.698 880.797L381.515 884.748L383.876 884.939L385.527 883.06Z"
						fill="#141516"
					/>
					<path
						d="M389.425 854.29L400.829 864.249L399.565 865.693L388.162 855.734L389.425 854.29Z"
						fill="#141516"
					/>
					<path
						d="M409.727 831.059L421.13 841.018L419.867 842.461L408.464 832.503L409.727 831.059Z"
						fill="#141516"
					/>
					<path
						d="M338.725 899.016L349.936 908.797L347.094 911.098L344.618 910.695L333.945 901.375L338.725 899.016Z"
						fill="#141516"
					/>
		</g>
	)
}
