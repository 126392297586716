import React from "react";
import s from './SellerContent.module.scss'

export const Circle = ({step}) => {
	const isEnd = step >= 7

  return (
    <svg
      width="870"
      height="870"
      viewBox="0 0 870 870"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="435"
        cy="435"
        r="433"
				className={step > 1 ? s.fill : step === 1 ? s.active : s.empty}
        fill="#EDF1FF"
        stroke="#A1B6FF"
        stroke-width="4"
        stroke-dasharray="8 8"
      />
      <circle
        cx="435"
        cy="435"
        r="381"
				className={step > 2 ? s.fill : step === 2 ? s.active : s.empty}
        stroke-dasharray="8 8"
      />
      <circle
        cx="435"
        cy="435"
        r="329"
				className={step > 3 ? s.fill : step === 3 ? s.active : s.empty}
        stroke-dasharray="8 8"
      />
      <circle
        cx="435"
        cy="435"
        r="277"
				className={step > 4 ? s.fill : step === 4 ? s.active : s.empty}
        stroke-dasharray="8 8"
      />
      <circle
        cx="435"
        cy="435"
        r="225"
				className={step > 5 ? s.fill : step === 5 ? s.active : s.empty}
        stroke-dasharray="8 8"
      />
      <circle
        cx="435"
        cy="435"
        r="173"
				className={step > 6 ? s.fill : step === 6 ? s.active : s.empty}
        stroke-dasharray="8 8"
      />
      <circle
        cx="435"
        cy="435"
        r="121"
        className={step > 7 ? s.active : step === 7 ? s.active : s.empty}
        stroke-dasharray="8 8"
      />
			<g>
				<path
					d="M399.858 395.607L418.46 384.575C418.624 384.465 418.788 384.383 418.952 384.273L419.253 384.136C425.836 380.623 434.741 381.254 444.574 386.962C464.761 398.681 481.15 427.168 481.15 450.605C481.15 461.473 477.599 469.295 471.808 473.439L471.153 473.905C470.716 474.18 470.279 474.454 469.842 474.701L452.523 485.048L442.717 468.581C426.054 457.055 412.724 434.468 410.184 414.022L408.9 411.854L399.886 395.635L399.858 395.607Z"
					fill={isEnd ? '#8EA7FF' : '#E9EAEA'}
				/>
				<path
					d="M444.983 386.633C463.394 397.309 478.582 421.926 481.122 443.936C480.958 454.393 477.462 461.967 471.807 466.029L471.152 466.496C470.715 466.77 470.278 467.044 469.841 467.291L452.522 477.638L442.716 461.171C426.053 449.645 412.723 427.058 410.183 406.613L408.899 404.444L402.944 393.714L418.869 384.273C419.033 384.163 419.197 384.081 419.361 383.971L419.661 383.834C426.244 380.321 435.149 380.952 444.983 386.661V386.633Z"
					fill="white"
				/>
				<path
					d="M417.723 391.409C417.886 391.299 418.05 391.217 418.214 391.107L418.515 390.97C425.098 387.457 434.003 388.088 443.837 393.796C456.484 401.151 467.629 415.066 474.185 430.215L429.769 457.714C418.897 445.556 410.948 429.062 409.063 413.748L407.78 411.58L401.825 400.85L417.75 391.409H417.723Z"
					fill={isEnd ? '#FABCBC' : '#F3F3F3'}
				/>
				<path
					d="M452.168 486.365L470.333 475.525C470.77 475.305 471.235 475.003 471.672 474.729L472.355 474.262C478.637 469.762 482.106 461.364 482.106 450.633C482.106 426.921 465.471 397.995 445.039 386.14C435.26 380.459 425.918 379.443 418.733 383.313L417.969 383.724L398.492 395.278L409.173 414.352C411.823 435.127 425.235 457.631 441.925 469.268L452.141 486.393L452.168 486.365ZM428.294 382.956C433.129 382.956 438.51 384.603 444.055 387.814C463.941 399.34 480.14 427.525 480.14 450.578C480.14 460.65 476.971 468.472 471.207 472.588L470.579 473.027C470.197 473.274 469.787 473.521 469.377 473.741L452.851 483.593L443.263 467.676C426.874 456.341 413.68 434.194 411.14 413.803L411.003 413.419L409.719 411.251L401.197 395.91L419.662 384.987C422.257 383.587 425.18 382.901 428.321 382.901L428.294 382.956Z"
					fill={isEnd ? '#141516' : '#BDBEC0'}
				/>
				<path
					d="M388.905 419.347C388.905 442.784 405.267 471.271 425.481 482.99C445.667 494.708 462.057 485.213 462.057 461.775C462.057 438.338 445.694 409.851 425.481 398.133C405.294 386.414 388.905 395.91 388.905 419.347Z"
					fill={isEnd ? '#8EA7FF' : '#E9EAEA'}
				/>
				<path
					d="M433.803 463.981L440.021 468.493C441.163 469.315 442.238 468.598 442.413 466.892L443.914 452.301C444.089 450.595 443.303 448.504 442.161 447.683L435.943 443.171C434.801 442.35 433.726 443.067 433.551 444.773L432.05 459.363C431.875 461.069 432.661 463.16 433.803 463.981Z"
					fill={isEnd ? '#8EA7FF' : '#E9EAEA'}
				/>
				<path
					d="M440.77 469.797C441.119 469.821 441.478 469.741 441.821 469.58C442.642 469.162 443.19 468.222 443.32 466.962L444.825 452.362C445.036 450.314 444.103 447.92 442.703 446.895L436.469 442.38C435.723 441.825 434.854 441.737 434.141 442.083C433.32 442.501 432.771 443.441 432.642 444.701L431.137 459.301C430.926 461.35 431.859 463.743 433.259 464.768L439.493 469.283C439.914 469.577 440.349 469.74 440.798 469.772L440.77 469.797ZM434.972 443.755C434.972 443.755 435.241 443.827 435.38 443.943L441.614 448.458C442.501 449.103 443.147 450.868 443.006 452.233L441.502 466.833C441.44 467.437 441.252 467.794 441.067 467.887C440.962 467.932 440.743 467.864 440.533 467.717L434.298 463.202C433.411 462.557 432.765 460.792 432.906 459.427L434.41 444.827C434.472 444.223 434.66 443.866 434.845 443.773C434.87 443.775 434.922 443.752 434.972 443.755Z"
					fill={isEnd ? '#141516' : '#BDBEC0'}
				/>
				<path
					d="M436.187 446.01L434.558 461.718C434.408 463.165 433.613 464.011 432.544 463.856L429.901 463.537C429.475 463.507 429.018 463.29 428.572 462.967L418.334 455.568C414.389 452.719 411.253 446.933 410.534 441.21L408.762 426.906C408.311 423.217 410.428 421.299 412.988 423.148L420.809 428.787C421.606 429.374 422.312 428.893 422.324 427.807L422.345 424.681C422.364 420.363 423.138 416.547 424.59 413.496L424.599 413.417C425.17 412.291 426.3 412.105 427.476 412.93C429.049 414.074 430.14 416.695 429.835 418.662L429.088 423.671C428.987 424.406 429.025 425.257 429.238 426.12L430.834 432.885C431.429 435.418 432.509 437.906 433.93 440.02L435 441.633C435.874 442.94 436.354 444.644 436.209 446.038L436.187 446.01Z"
					fill={isEnd ? '#8EA7FF' : '#E9EAEA'}
				/>
				<path
					d="M432.552 464.816C433.204 464.862 433.805 464.666 434.303 464.25C434.939 463.712 435.358 462.84 435.467 461.787L437.097 446.076C437.264 444.47 436.731 442.55 435.75 441.05L434.68 439.437C433.299 437.405 432.283 435.027 431.701 432.6L430.105 425.834C429.931 425.08 429.879 424.36 429.967 423.757L430.714 418.746C431.057 416.411 429.851 413.436 427.993 412.113C427.147 411.496 426.233 411.3 425.426 411.534C424.749 411.725 424.166 412.241 423.811 412.985C422.235 416.241 421.441 420.241 421.409 424.692L421.388 427.819C421.366 428.03 421.355 428.135 421.352 428.161L413.518 422.361C412.132 421.362 410.658 421.206 409.517 421.974C408.188 422.861 407.562 424.753 407.851 427.079L409.625 441.387C410.367 447.377 413.658 453.387 417.793 456.382L428.037 463.783C428.6 464.194 429.18 464.447 429.754 464.514L432.399 464.832C432.399 464.832 432.499 464.839 432.549 464.843L432.552 464.816ZM426.073 413.33C426.324 413.347 426.614 413.474 426.896 413.679C428.212 414.62 429.139 416.885 428.895 418.512L428.147 423.523C428.01 424.361 428.084 425.347 428.309 426.344L429.906 433.11C430.538 435.779 431.661 438.35 433.147 440.575L434.217 442.188C434.983 443.329 435.387 444.789 435.267 445.947L433.637 461.658C433.582 462.185 433.413 462.597 433.191 462.793C433.052 462.916 432.896 462.958 432.673 462.916L430.029 462.598C429.753 462.578 429.416 462.422 429.087 462.187L418.842 454.786C415.085 452.056 412.095 446.544 411.401 441.061L409.627 426.753C409.429 425.255 409.761 424.006 410.412 423.574C410.901 423.238 411.643 423.396 412.418 423.954L420.244 429.594C420.902 430.065 421.601 430.14 422.219 429.786C422.837 429.432 423.217 428.69 423.212 427.762L423.233 424.635C423.258 420.501 423.997 416.789 425.383 413.891C425.605 413.456 425.789 413.389 425.839 413.393C425.914 413.398 425.992 413.377 426.068 413.382L426.073 413.33Z"
					fill={isEnd ? '#141516' : '#BDBEC0'}
				/>
				<path
					d="M441.788 489C445.449 489 448.808 488.149 451.813 486.42C459.025 482.221 463.013 473.467 463.013 461.776C463.013 438.064 446.377 409.138 425.945 397.282C415.865 391.436 406.332 390.531 399.093 394.702C391.882 398.901 387.894 407.656 387.894 419.347C387.894 443.059 404.529 471.985 424.962 483.841C430.889 487.271 436.598 489 441.788 489ZM409.118 394.098C413.953 394.098 419.335 395.745 424.962 398.984C444.848 410.51 461.046 438.695 461.046 461.748C461.046 472.698 457.413 480.849 450.83 484.664C444.247 488.479 435.396 487.573 425.945 482.084C406.059 470.558 389.861 442.373 389.861 419.32C389.861 408.369 393.494 400.218 400.077 396.404C402.754 394.839 405.813 394.071 409.118 394.071V394.098Z"
					fill={isEnd ? '#141516' : '#BDBEC0'}
				/>
			</g>
    </svg>
  );
};
