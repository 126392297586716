import React from 'react'

export const Stars = () => {	
	return (
		<g>
			<g style={{opacity: 0}} id="publisher_star1">
				<path
					d="M526.889 291.263L507.865 270.762L511.033 268.116L511.148 268.404L531.631 250.53C531.881 250.3 531.669 249.897 531.343 249.955L518.078 252.716C518.078 252.716 517.924 252.621 517.848 252.582L494.274 241.325C493.064 240.75 491.874 239.618 491.126 238.333L487.958 232.887L488.188 233.194L493.928 227.133L482.257 200.822C482.161 200.611 481.892 200.553 481.719 200.707L470.623 210.391C470.412 210.583 470.239 210.833 470.143 211.159L465.229 225.868L449.545 216.49C449.334 216.356 449.065 216.394 448.892 216.547L438.603 225.523L437.758 226.271C436.702 227.21 436.99 229.531 438.853 231.621L458.721 254.001C459.739 255.152 460.391 256.533 460.43 257.683L461.178 279.968C461.274 282.864 464.845 286.277 467.225 285.74L485.501 281.579C486.442 281.368 487.709 281.79 488.88 282.729L511.551 300.871C513.183 302.175 514.68 302.329 515.544 301.696L526.832 291.877C527.024 291.724 527.024 291.436 526.87 291.263H526.889Z"
					fill="#7C99FF"
				/>
				<path
					d="M444.88 236.819L445.725 236.071L455.995 227.096C456.187 226.942 456.456 226.904 456.648 227.038L472.332 236.397L477.246 221.688C477.361 221.362 477.515 221.112 477.726 220.92L487.421 212.463L482.257 200.822C482.161 200.611 481.892 200.554 481.719 200.707L470.623 210.392C470.412 210.584 470.239 210.833 470.143 211.159L465.229 225.868L449.545 216.49C449.334 216.356 449.065 216.395 448.892 216.548L438.603 225.523L437.758 226.271C436.702 227.211 436.99 229.531 438.853 231.622L444.324 237.797C444.42 237.394 444.592 237.068 444.861 236.819H444.88Z"
					fill="white"
				/>
				<path
					d="M467.533 268.251C467.494 267.1 466.861 265.719 465.824 264.568L460.43 258.508L461.159 279.987C461.255 282.883 464.826 286.297 467.206 285.76L468.089 285.549L467.513 268.27L467.533 268.251Z"
					fill="#7C99FF"
				/>
				<path
					d="M462.1 284.225C460.968 282.94 460.276 281.406 460.219 280.006L459.47 257.722C459.432 256.801 458.875 255.67 458.011 254.634L438.142 232.273C435.973 229.819 435.57 226.942 437.125 225.561L448.259 215.838C448.758 215.397 449.469 215.34 450.045 215.685L464.711 224.43L469.241 210.852C469.395 210.392 469.645 209.989 470.009 209.682L481.105 199.978C481.412 199.71 481.835 199.595 482.238 199.691C482.641 199.787 482.986 200.055 483.14 200.439L495.08 227.326L489.36 233.366L491.97 237.854C492.623 238.985 493.641 239.964 494.696 240.462L518.251 251.7L531.151 249.015C531.727 248.9 532.303 249.169 532.572 249.706C532.841 250.243 532.706 250.856 532.265 251.24L510.803 269.976L510.668 269.67L509.267 270.839L527.6 290.592C527.849 290.842 527.984 291.244 527.965 291.609C527.945 291.973 527.773 292.337 527.485 292.587L516.197 302.406C514.815 303.422 512.837 303.096 510.956 301.6L488.285 283.458C487.363 282.73 486.365 282.346 485.712 282.499L467.437 286.661C466.208 286.949 464.769 286.508 463.386 285.434C462.906 285.069 462.484 284.666 462.1 284.225ZM459.489 253.426C460.622 254.73 461.332 256.303 461.39 257.664L462.139 279.949C462.177 281.234 463.175 282.864 464.557 283.938C465.46 284.628 466.381 284.954 467.015 284.82L485.29 280.658C486.538 280.371 488.054 280.869 489.475 282.001L512.146 300.143C513.433 301.178 514.527 301.293 514.987 300.948L525.815 291.551L506.483 270.724L511.398 266.62L511.513 266.87L529.193 251.432L517.944 253.771L517.445 253.503L493.871 242.246C492.489 241.574 491.145 240.328 490.32 238.87L487.152 233.424L487.882 232.945L487.517 232.599L492.815 226.999L481.719 201.973L471.257 211.101C471.257 211.101 471.103 211.293 471.046 211.446L465.767 227.287L449.315 217.468L438.392 226.999C437.835 227.479 438.046 229.339 439.582 230.988L459.451 253.369L459.509 253.426H459.489Z"
					fill="#141516"
				/>
				<path
					d="M488.112 203.373L502.586 228.342C503.335 229.627 504.506 230.758 505.735 231.333L529.308 242.591C532.38 244.048 534.319 248.248 532.591 249.686L519.326 260.771C518.635 261.346 518.501 262.459 518.942 263.744L527.504 288.694C528.617 291.935 525.968 293.219 523.012 290.861L500.34 272.719C499.169 271.779 497.902 271.357 496.962 271.568L478.686 275.73C476.306 276.267 472.735 272.872 472.639 269.957L471.891 247.673C471.852 246.522 471.219 245.141 470.182 243.991L450.294 221.63C447.702 218.715 448.144 215.339 451.043 215.896L473.254 220.249C474.405 220.479 475.269 220.057 475.576 219.117L481.585 201.148C482.372 198.808 486.211 200.112 488.093 203.373H488.112Z"
					fill="white"
				/>
				<path
					d="M452.367 223.528L470.393 243.818C471.41 244.969 472.063 246.35 472.102 247.5L472.85 269.785C472.946 272.68 476.517 276.094 478.897 275.557L497.173 271.396C498.113 271.185 499.38 271.607 500.551 272.546L517.713 286.258L512.05 269.765C511.609 268.481 511.743 267.368 512.434 266.793L519.095 261.231C519.211 260.982 519.345 260.752 519.537 260.579L526.179 255.037C526.909 253.254 525.085 249.878 522.417 248.613L498.843 237.355C497.633 236.78 496.443 235.649 495.695 234.364L481.22 209.394C480.779 208.647 480.241 207.994 479.646 207.458L475.807 218.964C475.499 219.904 474.616 220.306 473.484 220.096L470.585 219.52L468.704 225.158C468.397 226.079 467.514 226.501 466.381 226.29L452.367 223.548V223.528Z"
					fill="#F14C4C"
				/>
				<path
					d="M473.541 274.234C472.409 272.949 471.718 271.415 471.66 270.015L470.911 247.73C470.873 246.81 470.316 245.563 469.452 244.643L449.584 222.282C447.818 220.23 447.146 217.794 448.048 216.202C448.662 215.148 449.814 214.687 451.235 214.975L473.445 219.328C474.117 219.463 474.52 219.29 474.674 218.849L480.683 200.841C481.028 199.786 481.873 199.134 482.967 199.019C485.002 198.808 487.517 200.438 488.937 202.893L503.412 227.862C504.064 228.994 505.082 229.972 506.138 230.471L529.711 241.728C531.957 242.802 533.858 245.314 534.146 247.558C534.299 248.766 533.954 249.782 533.186 250.434L519.921 261.5C519.556 261.807 519.518 262.555 519.825 263.437L528.387 288.387C529.001 290.19 528.694 291.724 527.542 292.529C526.218 293.45 524.24 293.104 522.378 291.609L499.707 273.467C498.785 272.738 497.787 272.354 497.134 272.508L478.859 276.669C477.63 276.957 476.191 276.516 474.808 275.442C474.328 275.078 473.906 274.675 473.522 274.234H473.541ZM470.931 243.435C472.082 244.739 472.773 246.311 472.831 247.673L473.58 269.957C473.618 271.242 474.616 272.872 475.999 273.946C476.901 274.636 477.822 274.963 478.456 274.828L496.731 270.667C497.979 270.379 499.496 270.878 500.916 272.009L523.588 290.151C524.893 291.187 525.987 291.321 526.448 290.995C526.851 290.707 526.889 289.959 526.582 289.039L518.02 264.089C517.445 262.401 517.694 260.905 518.692 260.062L531.957 248.977C532.284 248.708 532.284 248.191 532.245 247.826C532.073 246.388 530.767 244.374 528.886 243.473L505.312 232.216C503.93 231.544 502.586 230.298 501.761 228.84L487.267 203.852C486.134 201.896 484.253 200.822 483.159 200.937C482.813 200.975 482.602 201.129 482.487 201.455L476.498 219.443C476.037 220.824 474.693 221.534 473.081 221.208L450.87 216.854C450.275 216.739 449.891 216.854 449.718 217.161C449.373 217.756 449.488 219.405 451.023 221.016L470.892 243.396L470.95 243.454L470.931 243.435Z"
					fill="#141516"
				/>
				<path
					d="M474.655 274.061L463.213 284.052L465.92 285.683L487.152 281.77L499.073 271.357L495.483 271.798L478.667 275.749L476.306 275.94L474.655 274.061Z"
					fill="#141516"
				/>
				<path
					d="M470.756 245.291L459.353 255.25L460.616 256.694L472.02 246.735L470.756 245.291Z"
					fill="#141516"
				/>
				<path
					d="M450.455 222.06L439.051 232.019L440.315 233.462L451.718 223.504L450.455 222.06Z"
					fill="#141516"
				/>
				<path
					d="M521.457 290.017L510.246 299.798L513.087 302.099L515.564 301.696L526.237 292.376L521.457 290.017Z"
					fill="#141516"
				/>
			</g>
      <g style={{opacity: 0}} id="publisher_star2">
        <path
          d="M333.292 900.262L352.316 879.762L349.149 877.115L349.034 877.403L328.551 859.529C328.301 859.299 328.512 858.896 328.839 858.954L342.104 861.715C342.104 861.715 342.257 861.62 342.334 861.581L365.908 850.324C367.117 849.749 368.307 848.617 369.056 847.332L372.224 841.886L371.993 842.193L366.253 836.133L377.925 809.821C378.021 809.61 378.29 809.552 378.463 809.706L389.558 819.39C389.769 819.582 389.942 819.832 390.038 820.158L394.953 834.867L410.636 825.489C410.848 825.355 411.116 825.393 411.289 825.546L421.579 834.522L422.423 835.27C423.479 836.209 423.191 838.53 421.329 840.62L401.46 863C400.443 864.151 399.79 865.532 399.752 866.682L399.003 888.967C398.907 891.863 395.337 895.276 392.956 894.739L374.681 890.578C373.74 890.367 372.473 890.789 371.302 891.728L348.631 909.87C346.999 911.174 345.502 911.328 344.638 910.695L333.35 900.876C333.158 900.723 333.158 900.435 333.311 900.262H333.292Z"
          fill="#7C99FF"
        />
        <path
          d="M415.301 845.817L414.457 845.069L404.186 836.094C403.994 835.941 403.726 835.902 403.534 836.037L387.85 845.395L382.935 830.686C382.82 830.36 382.667 830.111 382.455 829.919L372.761 821.462L377.925 809.821C378.021 809.61 378.29 809.552 378.463 809.706L389.558 819.39C389.769 819.582 389.942 819.832 390.038 820.158L394.953 834.867L410.636 825.489C410.848 825.355 411.116 825.393 411.289 825.546L421.579 834.522L422.423 835.27C423.479 836.209 423.191 838.53 421.329 840.62L415.858 846.795C415.762 846.393 415.589 846.067 415.321 845.817H415.301Z"
          fill="white"
        />
        <path
          d="M392.649 877.249C392.688 876.098 393.321 874.718 394.358 873.567L399.752 867.507L399.022 888.986C398.926 891.882 395.356 895.295 392.975 894.758L392.092 894.547L392.668 877.268L392.649 877.249Z"
          fill="#7C99FF"
        />
        <path
          d="M398.082 893.224C399.214 891.94 399.905 890.405 399.963 889.005L400.711 866.721C400.75 865.8 401.307 864.669 402.17 863.633L422.039 841.272C424.208 838.818 424.612 835.941 423.057 834.56L411.922 824.837C411.423 824.396 410.713 824.339 410.137 824.684L395.471 833.429L390.94 819.851C390.787 819.391 390.537 818.988 390.172 818.681L379.077 808.977C378.769 808.709 378.347 808.594 377.944 808.69C377.541 808.786 377.195 809.054 377.042 809.438L365.101 836.325L370.822 842.365L368.211 846.853C367.558 847.985 366.541 848.963 365.485 849.461L341.931 860.699L329.03 858.014C328.454 857.899 327.879 858.168 327.61 858.705C327.341 859.242 327.475 859.855 327.917 860.239L349.379 878.975L349.513 878.669L350.915 879.838L332.582 899.591C332.332 899.841 332.198 900.243 332.217 900.608C332.236 900.972 332.409 901.337 332.697 901.586L343.985 911.405C345.367 912.421 347.344 912.095 349.225 910.599L371.897 892.457C372.818 891.729 373.817 891.345 374.469 891.498L392.745 895.66C393.973 895.948 395.413 895.507 396.795 894.433C397.275 894.068 397.698 893.665 398.082 893.224ZM400.692 862.425C399.56 863.729 398.849 865.302 398.792 866.663L398.043 888.948C398.005 890.233 397.006 891.863 395.624 892.937C394.722 893.627 393.801 893.953 393.167 893.819L374.892 889.657C373.644 889.37 372.127 889.868 370.707 891L348.035 909.142C346.749 910.177 345.655 910.292 345.194 909.947L334.367 900.55L353.698 879.723L348.784 875.619L348.669 875.869L330.988 860.431L342.238 862.77L342.737 862.502L366.311 851.245C367.693 850.573 369.037 849.327 369.862 847.869L373.03 842.423L372.3 841.944L372.665 841.598L367.367 835.999L378.462 810.972L388.925 820.1C388.925 820.1 389.078 820.292 389.136 820.446L394.415 836.286L410.867 826.467L421.79 835.999C422.346 836.478 422.135 838.338 420.599 839.987L400.731 862.368L400.673 862.425H400.692Z"
          fill="#141516"
        />
        <path
          d="M372.07 812.372L357.595 837.341C356.847 838.626 355.676 839.757 354.447 840.332L330.873 851.59C327.802 853.047 325.863 857.247 327.591 858.685L340.856 869.77C341.547 870.345 341.681 871.458 341.24 872.743L332.678 897.693C331.564 900.934 334.214 902.219 337.17 899.86L359.841 881.718C361.012 880.778 362.279 880.356 363.22 880.567L381.495 884.729C383.876 885.266 387.446 881.871 387.542 878.956L388.291 856.672C388.329 855.521 388.963 854.14 390 852.99L409.888 830.629C412.479 827.714 412.038 824.338 409.139 824.895L386.928 829.248C385.776 829.478 384.912 829.056 384.605 828.116L378.597 810.147C377.81 807.807 373.97 809.111 372.089 812.372H372.07Z"
          fill="white"
        />
        <path
          d="M407.814 832.527L389.789 852.817C388.771 853.968 388.118 855.349 388.08 856.499L387.331 878.784C387.235 881.679 383.665 885.093 381.284 884.556L363.009 880.395C362.068 880.184 360.801 880.606 359.63 881.545L342.468 895.257L348.131 878.764C348.573 877.48 348.439 876.367 347.747 875.792L341.086 870.23C340.971 869.981 340.837 869.751 340.645 869.578L334.003 864.036C333.273 862.253 335.097 858.877 337.765 857.612L361.339 846.354C362.548 845.779 363.738 844.648 364.487 843.363L378.962 818.393C379.403 817.646 379.941 816.994 380.536 816.457L384.375 827.963C384.682 828.903 385.565 829.306 386.698 829.095L389.597 828.519L391.478 834.157C391.785 835.078 392.668 835.5 393.801 835.289L407.814 832.547V832.527Z"
          fill="#F14C4C"
        />
        <path
          d="M386.64 883.233C387.773 881.948 388.464 880.414 388.522 879.014L389.27 856.729C389.309 855.809 389.865 854.562 390.729 853.642L410.598 831.281C412.364 829.229 413.036 826.793 412.134 825.201C411.519 824.147 410.368 823.686 408.947 823.974L386.736 828.327C386.064 828.462 385.661 828.289 385.508 827.848L379.499 809.84C379.154 808.785 378.309 808.133 377.215 808.018C375.18 807.807 372.665 809.437 371.244 811.892L356.77 836.861C356.117 837.993 355.1 838.971 354.044 839.47L330.47 850.727C328.224 851.801 326.324 854.313 326.036 856.557C325.882 857.765 326.228 858.781 326.996 859.433L340.261 870.499C340.625 870.806 340.664 871.554 340.357 872.436L331.795 897.386C331.181 899.189 331.488 900.723 332.64 901.528C333.964 902.449 335.941 902.104 337.803 900.608L360.475 882.466C361.396 881.737 362.395 881.353 363.047 881.507L381.323 885.668C382.551 885.956 383.991 885.515 385.373 884.441C385.853 884.077 386.276 883.674 386.659 883.233H386.64ZM389.251 852.434C388.099 853.738 387.408 855.31 387.351 856.672L386.602 878.956C386.563 880.241 385.565 881.871 384.183 882.945C383.281 883.636 382.359 883.962 381.726 883.827L363.45 879.666C362.203 879.378 360.686 879.877 359.266 881.008L336.594 899.15C335.289 900.186 334.194 900.32 333.734 899.994C333.331 899.706 333.292 898.958 333.599 898.038L342.161 873.088C342.737 871.4 342.487 869.904 341.489 869.061L328.224 857.976C327.898 857.707 327.898 857.19 327.936 856.825C328.109 855.387 329.414 853.373 331.296 852.472L354.869 841.215C356.252 840.543 357.595 839.297 358.421 837.839L372.915 812.851C374.047 810.895 375.928 809.821 377.023 809.936C377.368 809.974 377.579 810.128 377.695 810.454L383.684 828.442C384.145 829.823 385.488 830.533 387.101 830.207L409.312 825.853C409.907 825.738 410.291 825.853 410.464 826.16C410.809 826.755 410.694 828.404 409.158 830.015L389.289 852.395L389.232 852.453L389.251 852.434Z"
          fill="#141516"
        />
        <path
          d="M385.527 883.06L396.968 893.052L394.262 894.682L373.03 890.769L361.109 880.356L364.698 880.797L381.515 884.748L383.876 884.939L385.527 883.06Z"
          fill="#141516"
        />
        <path
          d="M389.425 854.29L400.829 864.249L399.565 865.693L388.162 855.734L389.425 854.29Z"
          fill="#141516"
        />
        <path
          d="M409.727 831.059L421.13 841.018L419.867 842.461L408.464 832.503L409.727 831.059Z"
          fill="#141516"
        />
        <path
          d="M338.725 899.016L349.936 908.797L347.094 911.098L344.618 910.695L333.945 901.375L338.725 899.016Z"
          fill="#141516"
        />
      </g>
		</g>
	)
}
