import React from 'react'

export const Shus = () => {
	return (
		<g>
			<g className='shu_1'>
				<path d="M1165.53 213.982L1197.54 231.747L1198.14 266.49L1166.16 248.692L1165.56 213.95L1165.53 213.982ZM1190.15 235.755L1173.24 226.351L1173.55 244.717L1190.46 254.12L1190.15 235.755Z" fill="white"/>
				<path d="M1168.64 212.183L1200.66 229.949L1201.26 264.691L1169.24 246.926L1168.64 212.183ZM1193.26 233.956L1176.36 224.553L1176.67 242.918L1193.58 252.322L1193.26 233.956Z" fill="#FABCBC"/>
				<path d="M1198.17 266.49L1205.73 261.977L1205.1 227.235L1197.54 231.747L1198.17 266.49Z" fill="white"/>
				<path d="M1165.53 213.982L1173.08 209.469L1205.1 227.235L1197.54 231.747L1165.53 213.982Z" fill="#141516"/>
				<path d="M1173.55 244.716L1181.11 240.204L1180.95 230.643L1173.24 226.351L1173.55 244.716Z" fill="white"/>
				<path d="M1190.49 254.12L1173.55 244.716L1181.11 240.204L1190.33 245.316L1190.49 254.12Z" fill="#141516"/>
				<path d="M1172.29 224.773L1172.64 244.18V245.253L1173.58 245.758L1191.44 255.666L1191.09 235.187L1172.33 224.773H1172.29ZM1180.04 231.179L1180.2 239.667L1174.47 243.107L1174.18 227.929L1180.04 231.179ZM1189.55 252.542L1175.41 244.685L1181.14 241.245L1189.42 245.852L1189.55 252.542ZM1189.39 243.738L1182.02 239.636L1181.9 232.189L1189.26 236.291L1189.39 243.738Z" fill="#141516"/>
				<path d="M1205.1 226.194L1173.08 208.396L1165.53 212.909L1164.61 213.445V214.518L1165.21 249.26L1197.23 267.026L1198.14 267.531L1199.06 266.995L1206.61 262.482L1206.01 227.74V226.667L1205.07 226.162L1205.1 226.194ZM1167.38 213.95L1173.11 210.511L1203.27 227.267L1197.54 230.674L1185.71 224.111L1167.38 213.95ZM1197.23 264.912L1167.07 248.156L1166.5 215.528L1184.92 225.752L1196.66 232.284L1197.17 261.062L1197.23 264.912ZM1204.79 261.472L1199.06 264.912L1198.77 248.913L1198.49 232.284L1204.22 228.844L1204.79 261.472Z" fill="#141516"/>
			</g>

			<g className='shu_2'>
				<path d="M25.0189 571.806L29.9377 566.993L33.208 569.04C38.2065 568.961 44.2952 570.742 50.0647 574.358C55.8343 577.974 60.0884 582.68 62.1888 587.227L65.3262 589.194L63.3055 595.735C63.0928 596.399 62.8269 597.064 62.4547 597.676C58.5994 603.844 46.7678 603.392 36.0795 596.692C25.3912 589.992 19.8343 579.543 23.7161 573.374C24.0884 572.789 24.5403 572.284 25.0455 571.806H25.0189Z" fill="white"/>
				<path d="M24.8322 571.912L29.7509 567.099L33.0212 569.146C38.0198 569.067 44.1084 570.848 49.878 574.464C51.1542 575.262 52.3506 576.139 53.4673 577.016C56.3654 579.702 58.5722 582.653 59.9016 585.525L61.6298 586.615C61.7628 586.854 61.8957 587.093 62.002 587.306L62.9326 587.891L61.0449 594.033C60.8322 594.697 60.5663 595.362 60.1941 595.974C56.3388 602.142 44.5072 601.69 33.8189 594.99C32.5693 594.192 31.3728 593.368 30.2827 592.491C23.423 586.163 20.4186 578.426 23.556 573.454C23.9282 572.869 24.3802 572.364 24.8854 571.885L24.8322 571.912Z" fill="#8EA7FF"/>
				<path d="M35.6271 597.383C46.6877 604.322 59.0511 604.668 63.1456 598.127C63.3583 597.782 63.5444 597.409 63.7305 596.984H63.7571L64.1028 595.974L66.3096 588.822L62.8266 586.641C60.54 581.856 56.0732 577.15 50.4898 573.64C44.9063 570.13 38.7113 568.163 33.4204 568.189L29.8044 565.929L24.4071 571.194C23.7955 571.779 23.317 572.311 22.9447 572.895C18.8502 579.436 24.5134 590.417 35.574 597.356L35.6271 597.383ZM62.8531 594.458L62.5075 595.495C62.2948 596.186 62.0555 596.771 61.763 597.25C58.1471 603.02 46.8206 602.461 36.5311 596C26.2416 589.539 20.8177 579.596 24.4336 573.826C24.7261 573.374 25.0983 572.922 25.6301 572.443L30.0969 568.056L33.0215 569.891H33.2608C38.2859 569.811 44.2682 571.699 49.6656 575.076C55.0629 578.452 59.3701 583.025 61.4706 587.572L61.5769 587.785L64.3952 589.539L62.8797 594.432L62.8531 594.458Z" fill="#141516"/>
				<path d="M28.98 568.189C25.4173 573.852 30.5221 583.477 40.3596 589.645C50.2238 595.814 61.0716 596.239 64.6344 590.549C68.1972 584.886 63.0923 575.261 53.2548 569.093C43.4173 562.925 32.5428 562.499 28.98 568.189Z" fill="white"/>
				<path d="M28.7943 568.083C30.0174 566.142 32.1178 564.919 34.7234 564.387C39.2966 564.334 44.8534 565.982 50.1444 569.279C59.9819 575.474 65.0868 585.099 61.524 590.762C60.301 592.703 58.2006 593.926 55.5949 594.458C51.0218 594.511 45.465 592.863 40.174 589.566C30.3364 583.397 25.2316 573.773 28.7943 568.109V568.083Z" fill="white"/>
				<path d="M39.9344 590.364C50.1707 596.771 61.5769 597.064 65.379 591.028C69.1811 584.966 63.9433 574.836 53.7335 568.402C43.4972 561.994 32.091 561.702 28.3155 567.737C24.5134 573.799 29.7512 583.929 39.961 590.364H39.9344ZM52.8295 569.811C62.2682 575.74 67.2667 584.86 63.9433 590.124C60.6198 595.415 50.2505 594.883 40.8118 588.954C31.3731 583.025 26.3746 573.906 29.6981 568.641C33.0216 563.35 43.3908 563.882 52.8295 569.811Z" fill="#141516"/>
				<path d="M38.1793 573.134C36.5042 575.793 39.2162 580.499 44.2413 583.637C49.2398 586.774 54.6638 587.173 56.3388 584.514C58.0138 581.855 55.3019 577.149 50.2768 574.012C45.2782 570.874 39.8543 570.476 38.1793 573.134Z" fill="white"/>
				<path d="M50.1974 573.959C51.3938 574.703 52.4308 575.527 53.3347 576.405C54.8768 578.585 55.3554 580.792 54.3983 582.334C52.7232 584.993 47.3259 584.594 42.3008 581.456C41.1043 580.712 40.0674 579.888 39.1634 579.01C37.6213 576.83 37.1427 574.623 38.0999 573.081C39.7749 570.422 45.1723 570.821 50.1974 573.959Z" fill="#F8A5A5"/>
				<path d="M43.7895 584.355C49.2666 587.784 55.0894 588.05 57.0303 584.966C58.9712 581.882 56.2061 576.75 50.729 573.321C45.2519 569.891 39.4291 569.625 37.4882 572.709C35.5473 575.793 38.3124 580.925 43.7895 584.355ZM49.825 574.73C54.3715 577.575 57.0037 581.855 55.6211 584.089C54.212 586.322 49.2135 585.79 44.6669 582.945C40.1204 580.101 37.4882 575.82 38.8708 573.587C40.2799 571.353 45.2785 571.885 49.825 574.73Z" fill="#141516"/>
				<path d="M48.2569 577.096C44.3219 574.623 40.1476 573.852 37.7281 574.942C37.7281 574.277 37.861 573.639 38.2067 573.108C39.8817 570.449 45.279 570.848 50.3042 573.985C55.3027 577.122 58.0146 581.828 56.3662 584.487C55.994 585.072 55.4622 585.471 54.7975 585.763C54.7975 583.105 52.2717 579.595 48.2569 577.096Z" fill="#141516"/>
				<path d="M53.9732 585.764L55.1431 586.508C55.9939 586.136 56.632 585.604 57.084 584.913C59.0249 581.829 56.2598 576.697 50.7827 573.267C45.3056 569.837 39.4828 569.545 37.5419 572.656C37.1431 573.32 36.9304 574.091 36.9304 574.942L38.2332 577.016L38.1003 575.687C40.3602 574.703 44.2686 575.554 47.858 577.787C51.4474 580.047 53.9732 583.317 53.9998 585.764H53.9732ZM49.8787 574.676C54.4252 577.521 57.0574 581.802 55.6749 584.035C55.6217 584.142 55.5419 584.248 55.4356 584.328C54.7443 581.616 52.1918 578.585 48.7088 576.378C45.2524 574.198 41.5301 573.241 38.7915 573.746C38.8181 573.666 38.8713 573.586 38.9245 573.533C40.3336 571.3 45.3322 571.832 49.8787 574.676Z" fill="#141516"/>
				<path d="M44.2949 592.118L40.5194 598.978L32.4101 593.9L26.3481 587.519L23.5032 583.398L21.8282 578.027L23.5032 573.48L27.97 568.907L27.9168 573.401L29.2462 577.442L33.4205 583.956L40.3599 589.672L44.2949 592.118Z" fill="#141516"/>
				<path d="M62.1616 592.996L59.423 600.467L62.8794 596.957L64.5811 591.613L62.1616 592.996Z" fill="#141516"/>
			</g>
			
			<g className='shu_3'>
				<path d="M1325.21 844.805L1320.29 839.993L1317.02 842.04C1312.02 841.96 1305.94 843.742 1300.17 847.358C1294.4 850.973 1290.14 855.68 1288.04 860.226L1284.91 862.194L1286.93 868.734C1287.14 869.399 1287.4 870.064 1287.78 870.675C1291.63 876.843 1303.46 876.391 1314.15 869.691C1324.84 862.991 1330.4 852.542 1326.52 846.374C1326.14 845.789 1325.69 845.284 1325.19 844.805H1325.21Z" fill="white"/>
				<path d="M1325.4 844.911L1320.48 840.099L1317.21 842.146C1312.21 842.066 1306.12 843.848 1300.35 847.464C1299.08 848.261 1297.88 849.139 1296.76 850.016C1293.87 852.702 1291.66 855.653 1290.33 858.524L1288.6 859.614C1288.47 859.854 1288.34 860.093 1288.23 860.306L1287.3 860.891L1289.19 867.032C1289.4 867.697 1289.67 868.362 1290.04 868.973C1293.89 875.142 1305.72 874.69 1316.41 867.99C1317.66 867.192 1318.86 866.368 1319.95 865.49C1326.81 859.162 1329.81 851.425 1326.68 846.453C1326.3 845.868 1325.85 845.363 1325.35 844.885L1325.4 844.911Z" fill="#8EA7FF"/>
				<path d="M1314.6 870.383C1303.54 877.322 1291.18 877.668 1287.09 871.127C1286.87 870.781 1286.69 870.409 1286.5 869.984H1286.47L1286.13 868.974L1283.92 861.821L1287.4 859.641C1289.69 854.855 1294.16 850.149 1299.74 846.64C1305.33 843.13 1311.52 841.163 1316.81 841.189L1320.43 838.929L1325.82 844.194C1326.44 844.779 1326.91 845.31 1327.29 845.895C1331.38 852.436 1325.72 863.417 1314.66 870.356L1314.6 870.383ZM1287.38 867.458L1287.72 868.495C1287.94 869.186 1288.18 869.771 1288.47 870.25C1292.08 876.019 1303.41 875.461 1313.7 869C1323.99 862.539 1329.41 852.595 1325.8 846.826C1325.51 846.374 1325.13 845.922 1324.6 845.443L1320.13 841.056L1317.21 842.891H1316.97C1311.95 842.811 1305.96 844.699 1300.57 848.075C1295.17 851.452 1290.86 856.025 1288.76 860.572L1288.65 860.784L1285.84 862.539L1287.35 867.431L1287.38 867.458Z" fill="#141516"/>
				<path d="M1321.25 841.189C1324.81 846.852 1319.71 856.477 1309.87 862.645C1300.01 868.814 1289.16 869.239 1285.6 863.549C1282.03 857.886 1287.14 848.261 1296.98 842.093C1306.81 835.925 1317.69 835.499 1321.25 841.189Z" fill="white"/>
				<path d="M1321.44 841.082C1320.21 839.142 1318.11 837.919 1315.51 837.387C1310.93 837.334 1305.38 838.982 1300.09 842.279C1290.25 848.474 1285.14 858.099 1288.71 863.762C1289.93 865.703 1292.03 866.926 1294.64 867.458C1299.21 867.511 1304.77 865.862 1310.06 862.565C1319.89 856.397 1325 846.772 1321.44 841.109V841.082Z" fill="white"/>
				<path d="M1310.3 863.363C1300.06 869.771 1288.65 870.063 1284.85 864.028C1281.05 857.966 1286.29 847.836 1296.5 841.402C1306.73 834.994 1318.14 834.702 1321.92 840.737C1325.72 846.799 1320.48 856.929 1310.27 863.363H1310.3ZM1297.4 842.811C1287.96 848.74 1282.96 857.86 1286.29 863.124C1289.61 868.415 1299.98 867.883 1309.42 861.954C1318.86 856.025 1323.86 846.905 1320.53 841.641C1317.21 836.35 1306.84 836.882 1297.4 842.811Z" fill="#141516"/>
				<path d="M1312.05 846.134C1313.73 848.793 1311.02 853.499 1305.99 856.636C1300.99 859.774 1295.57 860.173 1293.89 857.514C1292.22 854.855 1294.93 850.149 1299.95 847.012C1304.95 843.874 1310.38 843.475 1312.05 846.134Z" fill="white"/>
				<path d="M1300.03 846.958C1298.84 847.703 1297.8 848.527 1296.9 849.404C1295.35 851.585 1294.88 853.791 1295.83 855.334C1297.51 857.992 1302.91 857.594 1307.93 854.456C1309.13 853.712 1310.16 852.887 1311.07 852.01C1312.61 849.83 1313.09 847.623 1312.13 846.081C1310.46 843.422 1305.06 843.821 1300.03 846.958Z" fill="#F8A5A5"/>
				<path d="M1306.44 857.354C1300.96 860.784 1295.14 861.05 1293.2 857.966C1291.26 854.882 1294.03 849.75 1299.5 846.32C1304.98 842.891 1310.8 842.625 1312.74 845.709C1314.68 848.793 1311.92 853.925 1306.44 857.354ZM1300.41 847.73C1295.86 850.574 1293.23 854.855 1294.61 857.088C1296.02 859.322 1301.02 858.79 1305.56 855.945C1310.11 853.1 1312.74 848.82 1311.36 846.586C1309.95 844.353 1304.95 844.885 1300.41 847.73Z" fill="#141516"/>
				<path d="M1301.97 850.096C1305.91 847.623 1310.08 846.852 1312.5 847.942C1312.5 847.277 1312.37 846.639 1312.02 846.108C1310.35 843.449 1304.95 843.848 1299.93 846.985C1294.93 850.122 1292.22 854.828 1293.87 857.487C1294.24 858.072 1294.77 858.471 1295.43 858.763C1295.43 856.105 1297.96 852.595 1301.97 850.096Z" fill="#141516"/>
				<path d="M1296.26 858.764L1295.09 859.508C1294.24 859.136 1293.6 858.604 1293.15 857.913C1291.21 854.829 1293.97 849.697 1299.45 846.267C1304.93 842.837 1310.75 842.545 1312.69 845.656C1313.09 846.32 1313.3 847.091 1313.3 847.942L1312 850.016L1312.13 848.687C1309.87 847.703 1305.96 848.554 1302.37 850.787C1298.78 853.047 1296.26 856.317 1296.23 858.764H1296.26ZM1300.35 847.676C1295.81 850.521 1293.17 854.802 1294.56 857.035C1294.61 857.142 1294.69 857.248 1294.8 857.328C1295.49 854.616 1298.04 851.585 1301.52 849.378C1304.98 847.198 1308.7 846.241 1311.44 846.746C1311.41 846.666 1311.36 846.586 1311.31 846.533C1309.9 844.3 1304.9 844.832 1300.35 847.676Z" fill="#141516"/>
				<path d="M1305.94 865.118L1309.71 871.978L1317.82 866.9L1323.88 860.519L1326.73 856.397L1328.4 851.027L1326.73 846.48L1322.26 841.907L1322.31 846.4L1320.99 850.442L1316.81 856.956L1309.87 862.672L1305.94 865.118Z" fill="#141516"/>
				<path d="M1288.07 865.995L1290.81 873.467L1287.35 869.957L1285.65 864.613L1288.07 865.995Z" fill="#141516"/>
			</g>
			
		</g>
	)
}
